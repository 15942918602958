
<template>

  <div>
    <Modal :options="{width: '35vw'}" v-if="formularioOpcionCatalogo">

      <div class="title">
        <div class="d-flex flex-wrap align-items-center">
          <div class="h2 text-white mr-auto">{{ !opcion_catalogo_data.id ? 'Agregar' : 'Editar' }} Opción</div>
          <span class="badge bg-none text-white py-1 float-right" v-if="catalogo_sistema">Catálogo de sistema</span>
        </div>
      </div>

      <div class="body">

        <div class="alert alert-primary mb-5" role="alert">
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> Los campos que contengan el icono <i class="fa fa-check-circle mx-sm-1"></i> indican que el campo es obligatorio.
        </div>

        <div class="row">

          <div class="col-sm-4 form-group">
            <label for="nombre" class="form-label text-truncate d-block" title="Nombre">
              <i class="fa fa-check-circle text-primary"></i>
              Nombre
            </label>
            <input v-model="opcion_catalogo_data.nombre" type="text" name="nombre" id="nombre" class="form-control">
          </div>

          <div class="col-sm-4 form-group">
            <label for="valor" class="form-label text-truncate d-block" title="Valor">
              <i class="fa fa-check-circle text-primary"></i>
              Valor
            </label>
            <input v-model="opcion_catalogo_data.valor" type="text" name="valor" id="valor" class="form-control">
          </div>

          <div class="col-sm-4 form-group">
            <label for="estatus" class="form-label text-truncate d-block" title="Estatus">
              <i class="fa fa-check-circle text-primary"></i>
              Estatus
            </label>
            <select class="form-control" name="estatus" id="estatus"  v-model="opcion_catalogo_data.estatus" >
              <option :value="1">Activo</option>
              <option :value="0">Inactivo</option>
            </select>
          </div>
        </div>
      </div>

      <div class="footer mb-4">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-danger" @click="cancelar_seleccion_opcion_catalogo"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          <div class="col-auto"><button type="button" class="btn btn-success" @click="guardar_opcion" id="btn-crear" name="btn-crear"><i class='fa fa-save mr-sm-1'></i>
            {{ !catalogo_data.id ? 'Agregar opción' : 'Guardar Cambios' }}</button></div>
        </div>
      </div>
    </Modal>

    <Modal :options="{width: '40vw'}">

      <div class="title">

        <div class="d-flex flex-wrap align-items-center">
          <div class="h2 text-white mr-auto">{{ !catalogo_data.id ? 'Agregar' : 'Editar' }} Catálogo </div>
          <span class="badge bg-none text-white py-1" v-if="catalogo_sistema">Catálogo de sistema</span>
        </div>

      </div>

      <div class="body">

        <div class="alert alert-primary mb-5" role="alert" v-if="catalogo_sistema">
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b>
          <br>Este es un catálogo del sistema, por lo tanto para el correcto funcionamiento del mismo el estatus asi como algunas de sus opciones podrían no tener la opción de editarse o eliminarse.
          <br><i class="fa fa-info-circle mr-sm-1"></i> Los campos que contengan el icono <i class="fa fa-check-circle mx-sm-1"></i> indican que el campo es obligatorio.
        </div>

        <div class="alert alert-primary mb-5" role="alert" v-else>
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> Los campos que contengan el icono <i class="fa fa-check-circle mx-sm-1"></i> indican que el campo es obligatorio.
        </div>

        <div class="row">

          <div class="col-sm-4 form-group">
            <label for="nombre" class="form-label text-truncate d-block" title="Nombre">
              <i class="fa fa-check-circle text-primary"></i>
              Nombre
            </label>
            <input v-model="catalogo_data.nombre" type="text" name="nombre" id="nombre" class="form-control">
          </div>


          <div class="col-sm-4 form-group">
            <label for="descripcion" class="form-label text-truncate d-block" title="Descripción">
              <i class="fa fa-check-circle text-primary"></i>
              Descripción
            </label>
            <input v-model="catalogo_data.descripcion" type="text" name="descripcion" id="descripcion" class="form-control">
          </div>

          <div class="col-sm-4 form-group">
            <label for="codigo" class="form-label text-truncate d-block" title="Código">
              <i class="fa fa-check-circle text-primary"></i>
              Código
            </label>
            <input v-model="catalogo_data.codigo" type="text" name="codigo" id="codigo" class="form-control" :disabled="editar_catalogo">
          </div>

          <div class="col-sm-4 form-group">
            <label for="estatus" class="form-label text-truncate d-block" title="Estatus">
              <i class="fa fa-check-circle text-primary"></i>
              Estatus
              <button  v-if="catalogo_sistema" type="button" class="btn btn-link btn-sm" data-toggle="popover" title="El estatus de un catálogo de sistema no se puede editar"><i class="fa fa-question-circle"></i></button>
            </label>
            <select class="form-control" name="estatus" id="estatus"  v-model="catalogo_data.estatus" :disabled="catalogo_sistema">
              <option :value="1">Activo</option>
              <option :value="0">Inactivo</option>
            </select>
          </div>
        </div>

        <hr>

        <h5 class="d-block"> Opciones ({{ catalogo_data.opciones.length }})</h5>


        <div class="row my-3">
          <!--<div class="col-sm-auto" v-if="$auth.can('bpb_apps_admin','catalogos_editar')">-->
          <div class="col-sm-auto">
            <button  class="btn btn-sm btn-primary" @click="agregar_opcion"><i class='fa fa-plus-circle'></i> Agregar opción</button>
          </div>
        </div>

        <div class="text-danger" v-if="validacion_opciones">
          * Debe agregar almenos una opción al catálogo .
        </div>


        <table class="table table-sm table-striped" id="tabla-catalogo-opciones">
          <thead>
          <tr class="bg-dark">
            <th scope="col" v-if="$auth.can('garantias_app','catalogos_editar') || $auth.can('garantias_app','catalogos_eliminar')"></th>
            <th scope="col">Nombre</th>
            <th scope="col">Valor</th>
            <th scope="col">Estatus</th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="!catalogo_data.opciones.length"><td colspan="4" class="text-secondary text-center"> Sin opciones agregados, agregue opciones usando el botón: <u><b>Agregar Opción</b></u> de arriba.</td></tr>
          <tr v-for="(item,index) in catalogo_data.opciones" :key="item.id">
            <td v-if="$auth.can('garantias_app','catalogos_editar')">
              <button  type="button" class="ml-3 btn btn-sm btn-primary" @click="editar_opcion(index)" :disabled="!item.editable"><i class="fa fa-edit"></i></button>
              <button class="btn btn-sm btn-dark ml-2" @click="pre_eliminar_opcion(index)" :disabled="!item.editable"><i class="fa fa-trash"></i></button>
            </td>
            <td>{{item.nombre}}</td>
            <td>{{item.valor}}</td>
            <td>{{!item.estatus ? 'Inactivo' : 'Activo'}}</td>
          </tr>
          </tbody>
        </table>




      </div>

      <div class="footer mb-4">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-danger" @click="$emit('cancelar')"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          <div class="col-auto"><button type="button" class="btn btn-success" @click="guardar" id="btn-crear" name="btn-crear"><i class='fa fa-save mr-sm-1'></i>Guardar
            {{ !catalogo_data.id ? '' : 'cambios' }}</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modalEliminarOpcionCatalogo" :options="{width: '30vw',type:'error'}" @close="modalEliminarOpcionCatalogo = false">
      <div class="title">Eliminar: {{ opcion_catalogo_seleccionado.nombre }} </div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-center">
            Eliminarás la opción {{ opcion_catalogo_seleccionado.nombre }} del catálogo {{catalogo_data.nombre}} <br/>¿Deseas continuar?
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row justify-content-end">
          <div class="row justify-content-end">
            <div class="col-auto"><button class="btn btn-primary" @click="eliminar_opcion_catalogo"><i class="fa fa-check-square mr-sm-1"></i>Continuar</button></div>
            <div class="col-auto"><button class="btn btn-outline-secondary" @click="cancelar_seleccion_opcion_catalogo"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
          </div>
        </div>
      </div>
    </Modal>

  </div>





</template>

<script>


import Modal from "@/components/Modal.vue";
import catalogosApi from "@/apps/garantias_app/api/catalogos/catalogos.api";
export default {
  name: "FormularioCatalogo"
  ,components: {Modal}
  ,props: {
    catalogo: {
      type: String | null,
      required: false,
      default() {
        return null;
      }
    },
  }
  ,data() {
    return {
      catalogo_data:{
        id: null
        ,nombre: null
        ,descripcion:null
        ,codigo: null
        ,estatus: null
        ,opciones: []
      }
      ,validacion_opciones: false
      ,opcion_catalogo_data:{
        nombre:null
        ,valor:null
        ,estatus: null
      }
      ,index_opcion_seleccionada:null
      ,formularioOpcionCatalogo: false
      ,modalEliminarOpcionCatalogo: false

    }
  }
  , mounted: function () {

     this.cargarCatalogo()

  }
  ,computed:{
    editar_catalogo(){
      return (!!this.catalogo_data.id || (typeof this.catalogo != "undefined" && typeof this.catalogo == "string"))
    },
    opcion_catalogo_seleccionado(){
      return this.index_opcion_seleccionada === null ? { nombre:null ,valor:null ,estatus: null } : {...this.catalogo_data.opciones[this.index_opcion_seleccionada]}
    }
    ,catalogo_sistema(){
      return this.editar_catalogo && this.catalogo_data.tipo && this.catalogo_data.tipo.toLowerCase() === 'sistema'
    }

  }
  ,methods:{

    async cargarCatalogo(){
      if (this.editar_catalogo) {
        const dataSource = await catalogosApi.buscar(this.catalogo)
        this.catalogo_data = dataSource.data
      }
    }

    ,cancelar(){
      this.$emit('cancelar')
    }

    ,agregar_opcion(){
      this.formularioOpcionCatalogo = true
      this.opcion_catalogo_data = { nombre:null ,valor:null ,estatus: null }
    }

    ,editar_opcion(index){
      this.index_opcion_seleccionada = index
      this.opcion_catalogo_data = this.opcion_catalogo_seleccionado
      this.formularioOpcionCatalogo = true
    }

    ,pre_eliminar_opcion(index){
      if (this.catalogo_data.opciones.length > 1) {
        this.index_opcion_seleccionada = index
        this.modalEliminarOpcionCatalogo = true
      }else{
        this.$helper.showMessage('Eliminar opción de catálogo', 'No se puede eliminar la opción seleccionada, el catálogo debe tener al menos una agregada.', 'error', 'alert')
      }
    }

    ,cancelar_seleccion_opcion_catalogo(){
      this.formularioOpcionCatalogo = false
      this.modalEliminarOpcionCatalogo = false
      this.index_opcion_seleccionada = null
    }

    ,async guardar_opcion(){

      let isValid = await this.$validator({
        nombre: !this.opcion_catalogo_data.nombre ? 'required' : 'max:255',
        valor: !this.opcion_catalogo_data.valor ? 'required': 'max:255',
        estatus: !this.opcion_catalogo_data.estatus ? 'required': '',
      })

      if (isValid) {

        if (this.editar_catalogo) {
          if (this.opcion_catalogo_data.hasOwnProperty('id')) {
            await catalogosApi.editar_opcion(this.catalogo_data.id, this.opcion_catalogo_data.id, this.opcion_catalogo_data)
            this.$helper.showMessage('Crear Opción Catálogo', 'Opción de catálogo guardada exitosamente.', 'success', 'alert')
          }else {
            await catalogosApi.crear_opcion(this.catalogo_data.id, this.opcion_catalogo_data)
            this.$helper.showMessage('Editar Opción Catálogo', 'Opción de catálogo actualizada exitosamente.', 'success', 'alert')
          }
        }

        if (this.index_opcion_seleccionada !== null && this.index_opcion_seleccionada >= 0)
          this.$set(this.catalogo_data.opciones, this.index_opcion_seleccionada, this.opcion_catalogo_data)
        else
          this.catalogo_data.opciones.push(this.opcion_catalogo_data)

        this.cancelar_seleccion_opcion_catalogo()
      }
    }

    ,async eliminar_opcion_catalogo(){
      const opcion_id = this.opcion_catalogo_seleccionado.id;
      let eliminado = false;

      if (!!opcion_id) {

        await catalogosApi.eliminar_opcion(this.catalogo_data.id, opcion_id).then(() => {
          this.$helper.showMessage('Eliminar opción de catálogo', 'Opción eliminada exitosamente.', 'success', 'alert')
          eliminado = true;
        })
      }

      if ( (!!opcion_id && eliminado) || !opcion_id) {
        this.catalogo_data.opciones.splice(this.index_opcion_seleccionada, 1)
        this.cancelar_seleccion_opcion_catalogo()
      }


    }

    ,async guardar(){

      this.validacion_opciones = (!this.catalogo_data.opciones.length > 0);

      let isValid = await this.$validator({
        nombre: !this.catalogo_data.nombre ? 'required' : 'max:255',
        descripcion: !this.catalogo_data.descripcion ? 'required': 'max:255',
        codigo: !this.catalogo_data.tipo ? 'required': 'max:255',
        estatus: !this.catalogo_data.valor ? 'required': '',
      })

      if (isValid && !this.validacion_seleccion_permisos) {
        try {
          if (this.editar_catalogo) {
            const request = await catalogosApi.editar(this.catalogo_data.id, this.catalogo_data)
            this.$helper.showMessage('Crear Catálogo', 'Información de catálogo guardada exitosamente.', 'success', 'alert')

          }else{
            const request = await catalogosApi.crear(this.catalogo_data)
            this.$helper.showMessage('Editar Catálogo', 'Información de catálogo guardada exitosamente.', 'success', 'alert')
          }

          this.$emit('actualizar')

        } catch (e) {
          this.$log.error('Error', e);
          if (e.response && e.response.status !== 400)
            this.$helper.showMessage( (!this.id ? 'Guardar' : 'Editar')+ ' Catálogo', 'Ocurrio un error al guardar catálogo del cliente, favor de reportarlo al administador.', 'error', 'alert')
        }
      }
    }



  }


}
</script>

<style scoped lang="scss">
#tabla-catalogo-opciones th:first-child{
    width: 110px;
  }

</style>
